import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Input from "@mui/material/Input";
import { TextareaAutosize } from '@mui/base';
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { toast } from 'react-toastify';
export default function NewModel(props) {
  const { handleSaveNewPrompt, savePrompt,
    promptData, setPromptData
  } = props;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 850,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState('');
  const [name, setName] = React.useState('');
  const [outputLength, setOutputLength] = React.useState(500);
  const [Modelprompt, setModelPrompt] = React.useState('');

  const [TextModelprompt, setTextModelPrompt] = React.useState('');

  const handleSave = () => {
    if (name.trim() == '' || TextModelprompt.trim() == '') {
      toast.error('All fields are required!')
      return false
    }
    let json = {
      name,
      prompt: TextModelprompt,
      outputLength
    };
    handleSaveNewPrompt(json);
    setName('');
    setOutputLength(500);
    setTextModelPrompt('');
    setOpen(false);
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setName('');
    setOutputLength(500);
    setTextModelPrompt('');
    setOpen(false);
  };

  return (
    <>
      <div className=" create_template">
        <Button onClick={handleOpen}> Prompt	&nbsp; <AddCircleIcon /></Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              class="modal_head"
              variant="h4"
              component="h2"
            >
              New Prompt
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="label_input">
                <Typography component="label">Name</Typography>
                <Input
                  placeholder="Enter the name"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </div>
              <div className="label_input">
                <Typography component="label">Output Length</Typography>
                <div className="language_slider">
                  <div className="slider_box">
                    <Box sx={{ width: 300 }}>
                      <Slider
                        aria-label="Custom marks"
                        step={100}
                        min={100}
                        max={8000}
                        value={outputLength}
                        onChange={(event) => {
                          setOutputLength(event.target.value);
                        }}
                        valueLabelDisplay="auto"
                      />
                    </Box>
                  </div>
                </div>
              </div>
              <div className="label_input">
                <Typography component="label">Prompt</Typography>
                <TextareaAutosize
                  value={TextModelprompt}
                  onChange={(event) => {
                    setTextModelPrompt(event.target.value);
                  }}
                />
              </div>
              <div className="flow_delete_btn">
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleSave}>
                    Save
                  </Button>
                </Stack>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
}
