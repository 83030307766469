import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import PromptTestOutputModal from './PromptTestOutputModal';
import Dropzone from '../pages/Dropzone';
import { toast } from 'react-toastify';
import { TextareaAutosize } from '@mui/base';

const TestModal = (props) => {
  const {
    setOpen,
    prompt,
    userFields,
    open,
    result,
    promptData,
    setPromptData,
    testUserField,
  } = props;
  const [loading, setLoading] = useState(false);
  const [showOutputModal, setShowOutputModal] = useState(false);
  const [testUserFields, setTestUserFields] = useState([]);
  const [isResultString, setResultString] = useState([]);
  const [openPrompt, setOpenPrompt] = useState(false); // Set initial state to false

  useEffect(() => {
    setTestUserFields(testUserField);

  }, [open, userFields, prompt]);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const handleInputChange = (event, id) => {
    const { value } = event.target;
    setTestUserFields((prevUserFields) => {
      return prevUserFields.map((field) => {
        if (field.id === id) {
          return {
            ...field,
            instruction: value,
          };
        }
        return field;
      });
    });
  };

  const handleDropzoneChange = async (acceptedFiles, id) => {
    if (acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];
    const reader = new FileReader();
    const fileTypes = [
      'application/pdf',
      // 'text/csv',
      'text/plain',
      'text/html',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
      'application/msword', // doc
      'application/json', // json
      // 'image/jpeg', // jpeg
      // 'image/jpg', // jpg
      // 'image/png', // png
      // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
    ];

    const readAsTextPromise = new Promise((resolve, reject) => {
      reader.onload = (e) => {
        const content = e.target.result;
        if (!fileTypes.includes(file.type)) {
          toast.error(
            'Unsupported file type! Only txt, csv, and html files are allowed.'
          );
          reject(new Error('Unsupported file type'));
        }
        resolve(content);
      };

      reader.readAsArrayBuffer(file); // Read the file based on bufferType
    });

    try {
      let updatedFields = [...testUserFields];
      const jsonObj = updatedFields.findIndex((field) => field.id == id);

      switch (file.type) {
        case 'text/plain':
          const buffer = await readAsTextPromise;
          const base64 = btoa(
            new Uint8Array(buffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );

          updatedFields[jsonObj].file = base64;
          updatedFields[jsonObj].fileType = 'text/txt';
          break;

        case 'text/html':
          const bufferHtml = await readAsTextPromise;
          const base64Html = btoa(
            new Uint8Array(bufferHtml).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );

          updatedFields[jsonObj].file = base64Html;
          updatedFields[jsonObj].fileType = file.type;
          break;

        case 'application/pdf':
          const bufferPdf = await readAsTextPromise;
          const base64Pdf = btoa(
            new Uint8Array(bufferPdf).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );

          updatedFields[jsonObj].file = base64Pdf;
          updatedFields[jsonObj].fileType = file.type;
          break;

        case 'text/csv':
          const bufferCsv = await readAsTextPromise;

          const base64Csv = btoa(
            new Uint8Array(bufferCsv).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          updatedFields[jsonObj].file = base64Csv;
          updatedFields[jsonObj].fileType = file.type;
          break;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          const bufferPpt = await readAsTextPromise;

          const base64Ppt = btoa(
            new Uint8Array(bufferPpt).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          updatedFields[jsonObj].file = base64Ppt;
          updatedFields[jsonObj].fileType = 'text/ppt';
          break;

        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          const bufferDocx = await readAsTextPromise;

          const base64Docx = btoa(
            new Uint8Array(bufferDocx).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          updatedFields[jsonObj].file = base64Docx;
          updatedFields[jsonObj].fileType = 'text/docx';
          break;

        case 'application/msword':
          const bufferDoc = await readAsTextPromise;

          const base64Doc = btoa(
            new Uint8Array(bufferDoc).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          updatedFields[jsonObj].file = base64Doc;
          updatedFields[jsonObj].fileType = 'text/doc';
          break;

        case 'application/json':
          const bufferJson = await readAsTextPromise;

          const base64Json = btoa(
            new Uint8Array(bufferJson).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          updatedFields[jsonObj].file = base64Json;
          updatedFields[jsonObj].fileType = 'text/json';
          break;

        case 'image/jpeg':
          const bufferJpeg = await readAsTextPromise;

          const base64Jpeg = btoa(
            new Uint8Array(bufferJpeg).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          updatedFields[jsonObj].file = base64Jpeg;
          updatedFields[jsonObj].fileType = 'image/jpeg';
          break;
        case 'image/jpg':
          const bufferJpg = await readAsTextPromise;

          const base64Jpg = btoa(
            new Uint8Array(bufferJpg).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          updatedFields[jsonObj].file = base64Jpg;
          updatedFields[jsonObj].fileType = 'image/jpg';
          break;
        case 'image/png':
          const bufferpng = await readAsTextPromise;

          const base64png = btoa(
            new Uint8Array(bufferpng).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          updatedFields[jsonObj].file = base64png;
          updatedFields[jsonObj].fileType = 'image/png';
          break;

        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          const bufferxlsx = await readAsTextPromise;

          const base64xlsx = btoa(
            new Uint8Array(bufferxlsx).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          updatedFields[jsonObj].file = base64xlsx;
          updatedFields[jsonObj].fileType = 'text/xlsx';
          break;
        default:
          throw new Error('Unsupported file type');
      }

      setTestUserFields(updatedFields);
    } catch (error) {
      console.error(error);
    }
  };

  const handleTest = async () => {
    try {
      const allFieldsFilled = testUserFields.every((field) => {
        if (field.type === 2) {
          return field.instruction.trim() !== '';
        } else if (field.type === 3) {
          return field.file !== undefined;
        } else if (field.type === 4) {
          return (
            field.instruction.trim() !== '' &&
            field.instruction.startsWith('http')
          );
        } else {
          return field.instruction.trim() !== '';
        }
      });

      if (!allFieldsFilled) {
        toast.error('Please fill in all fields');
        return;
      }
      setLoading(true);
      const myPrompt = {
        promptId: result.promptData.promptId,
        ...prompt,
        userFields: testUserFields,
      };
      console.log({myPrompt})

      /* commented on 21 Nov */

      const response = await axios.post('/api/save-prompt-details', myPrompt);

      setLoading(false);

      if (response.data.success === true) {
        setPromptData({
          ...promptData,
          prompts: response.data.prompt.Prompts,
          testUserFields: response.data.prompt.testUserFields
        });
        setResultString(response.data.resultString);
        setShowOutputModal(true);
        setOpen(false);
        setOpenPrompt(true);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
        setResultString('');
        setShowOutputModal(false);
        setOpen(true);
        setOpenPrompt(false);
      }

      /* commented on 21 Nov */


    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileRemove = (file, fieldId) => {
    const updatedFields = testUserFields.map((field) =>
      field.id === fieldId
        ? { ...field, file: undefined }
        : // ? { ...field, instruction: field.placeholder, file: undefined }
          field
    );
    setTestUserFields(updatedFields);
  };
  
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            maxHeight: '600px',
            overflowY: 'auto',
            boxShadow: 24,
            p: 4,
            width: '51%',
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ color: '#1976d2', marginBottom: '35px' }}
          >
            {prompt?.name}
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="body1"
            component="label"
            sx={{ fontWeight: '600', marginBottom: '15px', fontSize: '20px' }}
          >
            Enter Test Data
          </Typography>
          <hr
            style={{
              width: '100%', // or any specific width you need
              border: 'none',
              borderTop: '2px solid #ccc', // color and thickness of the line
              marginBottom: '15px', // space between the line and any content below
            }}
          />
          <div>
            {/* <div key={field.id} className="label_input label_input1">
                <Typography component="label">{field.name}</Typography>
                <TextField
                  name={field.name}
                  placeholder={field.instruction}
                  value={testInputValues[field.id] || ''}
                  onChange={(e) => handleInputChange(e, field.id)}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    sx: {
                      '&::placeholder': {
                        color: '#909090',
                      },
                      '& input::placeholder': {
                        color: '#909090',
                      },
                    },
                  }}
                />
              </div> */}
            {testUserFields?.map((field) => (
              <div className="chat_label_input" key={field.id}>
                <Typography component="label">{field.name}</Typography>
                {field.type === 2 ? (
                  <div className="prompt_select">
                    <TextField
                      name={field.name}
                      placeholder={field.placeholder}
                      value={field.instruction}
                      onChange={(e) => handleInputChange(e, field.id)}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      InputProps={{
                        sx: {
                          '&::placeholder': {
                            color: '#909090',
                          },
                          '& input::placeholder': {
                            color: '#909090',
                          },
                        },
                      }}
                    />
                  </div>
                ) : field.type === 3 ? (
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      handleDropzoneChange(acceptedFiles, field.id)
                    }
                    onRemove={(acceptedFiles) =>
                      handleFileRemove(acceptedFiles, field.id)
                    }
                  />
                ) : field.type === 4 ? (
                  <div className="prompt_select">
                    <TextField
                      placeholder={field.placeholder}
                      value={field.instruction}
                      onChange={(event) => handleInputChange(event, field.id)}
                      error={
                        field.instruction &&
                        !field.instruction.startsWith('http')
                      }
                      helperText={
                        field.instruction &&
                        !field.instruction.startsWith('http')
                          ? 'URL must start with http'
                          : ''
                      }
                    />
                  </div>
                ) : (
                  <div className="prompt_select">
                    <TextareaAutosize
                      placeholder={field.placeholder}
                      value={field.instruction}
                      onChange={(event) => handleInputChange(event, field.id)}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="flow_delete_btn" style={{ gap: '15px' }}>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleTest} disabled={loading}>
              {loading ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Submitting</span>
                  <CircularProgress
                    size={24}
                    style={{ marginTop: '6px', marginLeft: '10px' }}
                  />
                </div>
              ) : (
                'Submit'
              )}
            </Button>
          </div>
        </Box>
      </Modal>
      {showOutputModal && (
        <PromptTestOutputModal
          result={result}
          userFieldId={result.promptData._id}
          id={prompt.id}
          setShowOutputModal={setShowOutputModal}
          prompt={prompt}
          openPrompt={openPrompt}
          setOpenPrompt={setOpenPrompt}
          isResultString={isResultString}
        />
      )}
    </>
  );
};

export default TestModal;
